html, body {
    /* background-color: #90a4ae; */
    background-color: #d7ccc8;
    margin: 0;
    height: 100%;
    overflow-y: auto;
}

body {
    display: flex;
}

/* p {

    color:whitesmoke;
} */
#root {
    display: flex;
    justify-content: center;
    flex: 1;
}

.__bc-logout {
    color: white;
    cursor: pointer;
}

.__bc-logout:hover {
    color: #e7e7e7;
}

.__bc-ticket-scanner-close {
    color: white;
    cursor: pointer;
    font-size: 28px !important;
}

.__bc-ticket-scanner-close:hover {
    color: #e7e7e7;
}

/* .__bc-container-middle { */
/* padding-top: 200px !important; */
/* } */

.__bc-container {
    position: relative;
    padding: 30px 25px;
    flex: 1;
    display: flex;
    background-color: white;
    border-radius: 20px 20px 0 0;
}


.__bc-events-qr-img {
    margin: auto;
    max-width: 75%;
    opacity: 0.75;
}

.__bc-events-button-round button {
    z-index: 2222222222 !important;
    padding: 0px !important;
    position: absolute !important;
    bottom: 40px !important;
    right: 30px !important;
    height: 60px !important;
    width: 60px !important;
    border-radius: 60px !important;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;

}

.__bc-events-button-round-show button {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in;
}

.__bc-events-select {
    max-height: calc(100% - 160px) !important;
    top: 10px !important;
}

.__bc-ticket-scanner-mask {
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    z-index: 2147483639;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px 10px;
}

.__bc-ticket-scanner-mask p {
    color: white;
}

.__bc-ticket-scanner-mask-header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 20px);
    z-index: 2147483641;
    padding: 10px 10px;
    display: grid;
    display: flex;
    justify-content: space-between;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.__bc-ticket-scanner-mask-header div {
    display: flex;
    align-items: center;
}

.__bc-ticket-scanner-mask-header :first-child {
    flex-wrap: wrap;
}

.__bc-ticket-scanner-mask-header span {
    color: white !important;
    font-size: 100%;
    text-align: left;
    background-color: #d7ccc8;
    border-radius: 20px;
    padding: 1px 10px;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 2px 5px 2px 0px;

}

.__bc-ticket-scanner-mask-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 20px);
    z-index: 2147483641;
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 1fr 6fr 1fr;
    color: white !important;
}

.__bc-ticket-scanner-mask-footer p {
    color: white !important;
}

.__bc-ticket-scanner-mask-manual-search {
    position: absolute;
    bottom:70px;
    left: 0px;
    width: calc(100%);
    z-index: 2147483641;
    color: white !important;
    display: flex;
    justify-content: center;
}

.__bc-ticket-scanner-mask-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(60% + 30px);
    aspect-ratio: 1/1;
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.9);
    z-index: 2147483640;

}

.__bc-ticket-scanner-mask-square-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(60% + 70px) !important;
    aspect-ratio: 1/1;
    /* box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.9); */
    z-index: 2147483640;
}

.__bc-ticket-scanner-mask-hole {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    aspect-ratio: 1/1;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
    z-index: 2147483640;
}

.__bc-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    flex: 1;
}

.__bc-grid-parent {
    display: flex !important;
    flex: 1 !important;
}

.__bc-grid-child {
    display: flex !important;
    flex: 1 !important;
    justify-content: space-between !important;
    flex-direction: column !important;

}

.__bc-header-img {
    width: 200px;
}

.__bc-header-content {
    /* background-color: #90a4ae; */
    background-color: #d7ccc8;
    width: calc(100% - 40px);
    top: 0px;
    padding: 35px 20px;
    /* display: flex; */
    /* justify-content: space-between; */
    display: grid;
    grid-template-columns: 1fr 150px 1fr;
}

.__bc-ticket-details-line {
    display: flex;
    flex-direction: column;
    max-height: 100% !important;
    overflow: hidden;
}

.__bc-ticket-details-line div:first-child {
    flex: 0 0 auto;
}

.__bc-ticket-details-line div:last-child {
    flex: 0 1 auto;
    overflow-y: scroll;
}

.__bc-ticket-details-line p {
    display: flex;
    justify-content: space-between;
}

.__bc-ticket-details-line p :first-child {
    font-weight: bold;
}

.__bc-ticket-details-button-continue {
    margin-top: 20px;
}

.__bc-container div {
    max-height: 850px;
}

hr {
    margin: 30px 0px !important;
}

@media screen and (max-height: 670px) {
    .__bc-header-content {
        padding: 25px 20px;
        width: calc(100% - 40px);
    }

    .__bc-container {
        padding: 15px 20px;
    }

    .__bc-ticket-details-button-continue {
        margin-top: 10px;
    }

    hr {
        margin: 20px 0px;
    }
}
@media screen and (max-height: 580px) {
    .__bc-header-content {
        padding: 25px 15px;
        width: calc(100% - 30px);
    }

    .__bc-container {
        padding: 15px 15px;
    }

    .__bc-ticket-details-button-continue {
        margin-top: 10px;
    }

    hr {
        margin: 20px 0px;
    }
}