.MuiButton-root {
    /* background-color: #90a4ae!important; */
    /* background-color: #d7ccc8!important; */
    background-color: #757575!important;
    border-radius: 30px!important;
}

.MuiButton-root:hover {
    /* background-color: #78909c!important; */
    /* background-color: #d7ccc8!important; */
    background-color: #494949!important;
}

.MuiButton-root.Mui-disabled {
    /* background-color: #cfd8dc!important; */
    /* background-color: #efebe9!important; */
    background-color: #a4a4a4!important;
    color: whitesmoke!important;
}

.MuiTooltip-popper {
    z-index: 2147483647!important;
}

.MuiTypography-root {
    /* color: #90a4ae!important; */
    /* color: #d7ccc8!important; */
    color: #757575;
}

.MuiTypography-h1 {
    font-size: 30px!important;
    font-weight: 400!important;
}

.MuiTypography-h2 {
    font-size: 28px!important;
    font-weight: 400!important;
}

.MuiTypography-h3 {
    font-size: 26px!important;
    font-weight: 400!important;
}

.MuiTypography-h4 {
    font-size: 24px!important;
}